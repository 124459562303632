.login-container {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/background.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
}
