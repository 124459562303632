.bg-grad {
  color: #fff;
  /* background: linear-gradient(to right, #051d39, #1e344d); */
  background: linear-gradient(to right, #564093, #4e3e87);
  background-color: #051d39;
  width: 14em;
  max-width: 18em;
  -webkit-transition: width 0.2s ease-in-out;
  -moz-transition: width 0.2s ease-in-out;
  -ms-transition: width 0.2s ease-in-out;
  -o-transition: width 0.2s ease-in-out;
  transition: width 0.2s ease-in-out;
}
