.nav-container {
  width: 70vw;
  margin: auto;
  padding: 15px 0px;
}
.attribute-header {
  color: #a48a4b !important;
}
.footer {
  background-color: #2b2b2b;
}
